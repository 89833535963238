import { Component } from '@angular/core';
import { BackButtonEvent } from '@ionic/core';
import { Platform, ModalController, LoadingController, ToastController, AlertController, NavController, IonicModule } from '@ionic/angular';
// import { HeaderColor } from '@ionic-native/header-color/ngx';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';

import { WindowRef } from './services/window-ref';
import { EventBusService, EmitEvent } from './services/event-bus.service';
import { AuthService } from './services/auth-service';
import { MessageService } from './services/message.service';
// import { WebPushingService } from './services/webpushing.service';

import { Capacitor } from '@capacitor/core';
import { LocalStorageService } from './services/local-storage';
import { Keyboard } from '@capacitor/keyboard';
import { register } from 'swiper/element/bundle';

import * as _ from 'lodash';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { WebPushService } from './services/web-push.service';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class AppComponent {
  private objWindow: any;
  private loader: any;

  constructor(
    private platform: Platform,
    private router: Router,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private storage: LocalStorageService,
    private translate: TranslateService,
    private windowRef: WindowRef,
    private authService: AuthService,
    private eventBusService: EventBusService,
    private messageService: MessageService,
    private webPushService: WebPushService,
    private navCtrl: NavController,
  ) {
    if (!Capacitor.isNativePlatform()) {
      document.body.setAttribute("class", "app-root");
    }

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isNativePlatform()) {
        StatusBar.setStyle({
          style: Style.Light,
        });

        if (Capacitor.isNativePlatform()) {
          Keyboard.addListener('keyboardWillShow', (info) => {
            console.log('keyboard will show with height:', info.keyboardHeight);
          });

          Keyboard.addListener('keyboardDidShow', (info) => {
            console.log('keyboard did show with height:', info.keyboardHeight);
          });

          Keyboard.addListener('keyboardWillHide', () => {
            console.log('keyboard will hide');
          });

          Keyboard.addListener('keyboardDidHide', () => {
            console.log('keyboard did hide');
          });
        }

        SplashScreen.hide();
      }
    });

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      document.addEventListener(
        'ionBackButton',
        (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        },
        false,
      );
    });

    this.setupDefaults();

    this.objWindow = this.windowRef.nativeWindow;
    this.setupEvents();

    if (Capacitor.isNativePlatform()) {
      this.setupPushNotification();
    }
    // else this.setupWebPushNotifications();
  }

  async setupDefaults() {
    this.translate.setDefaultLang(environment.defaultLang);

    try {
      const supportedLangs = ['en', 'es', 'ar'];
      const browserLang = navigator.language.substr(0, 2);

      let lang = await this.storage.getStorage('lang');

      if (_.isEmpty(lang) && supportedLangs.indexOf(browserLang) !== -1) {
        lang = browserLang;
      }

      lang = lang || environment.defaultLang;

      if (lang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }

      this.storage.setStorage('lang', lang);
      this.translate.use(lang);
    } catch (error) {
      console.log(error);
    }

    try {
      const unit = (await this.storage.getStorage('unit')) || environment.defaultUnit;
      await this.storage.setStorage('unit', unit);
    } catch (error) {
      console.log(error);
    }
  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter');
  }

  setupEvents() {
    this.eventBusService.on('user:logout', () => {
      this.onLogOut();
    });

    this.eventBusService.on('app:CheckRegisterPush', async () => {
      if (Capacitor.isNativePlatform()) {
        this.registerNotifications();
      }
    });
  }

  async showNotification(notification: any) {
    const trans = await this.translate.get(['NOTIFICATION', 'OK']).toPromise();
    this.showAlert(trans.NOTIFICATION, notification.alert, trans.OK);
  }

  async showAlert(title: string = '', message: string = '', okText: string = 'OK') {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [okText],
    });
    return await alert.present();
  }

  async showToast(message: string = '') {
    let alert = await this.toastCtrl.create({
      message: message,
      duration: 3000,
    });

    return await alert.present();
  }

  async showLoadingView() {
    const loadingText = await this.translate.get('LOADING').toPromise();

    this.loader = await this.loadingCtrl.create({
      message: loadingText,
    });

    return await this.loader.present();
  }

  async dismissLoadingView() {
    if (!this.loader) return;

    try {
      await this.loader.dismiss();
    } catch (error) {
      console.log('ERROR: LoadingController dismiss', error);
    }
  }

  async onLogOut() {
    try {
      if (Capacitor.isNativePlatform()) {
        PushNotifications.removeAllListeners();

        GoogleAuth.signOut().then(() => {
          console.log('Google logout');
        })

        FacebookLogin.logout().then(() => {
          console.log('Facebook logout');
        })

      }
      else {
        this.webPushService.RemoveSubscriptions();
      }



      await this.authService.signOut();
      await this.storage.clear();
      this.eventBusService.emit(new EmitEvent('login:remember'));
      this.dismissLoadingView();
      this.navCtrl.navigateRoot('/start');
    } catch (err: any) {
      console.log(err.message);
      this.dismissLoadingView();
    }
  }

  goTo(page: string) {
    this.router.navigate([page]);
  }

  async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  async RegisteDevice(token: string, deviceArn: string) {
    // let profile = await this.storage.getProfile();
    if (token != null && token?.length > 0) {
      let userId = localStorage.getItem('IdUser');
      this.messageService.RegisterDevice(userId, token, Capacitor.getPlatform(), deviceArn).then(
        (res) => {
          console.log('🚀 ~ file: app.component.ts:490 ~ AppComponent ~ RegisteDevice ~ res:', res);
        },
        (err) => {
          console.log('🚀 ~ file: app.component.ts:487 ~ AppComponent ~ RegisteDevice ~ err:', err);
          console.log('REGISTER_DEVICE', JSON.stringify(err));
        },
      );
    }
  }

  async setupPushNotification() {
    await PushNotifications.addListener('registration', (token: Token) => {
      console.log('Registration: ', JSON.stringify(token));
      this.RegisteDevice(token.value, '');
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', JSON.stringify(err));
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('Push notification received: ' + JSON.stringify(notification));
      this.ProcessNotification(notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      console.log('Push action performed: ' + JSON.stringify(notification.notification));
    });
  }

  ProcessNotification(notification: PushNotificationSchema) {
    let noti = null;

    if (notification.data && notification.data?.payload) {
      noti = JSON.parse(notification.data.payload);
    }

    this.eventBusService.emit(new EmitEvent('tabs:checkMessages'));

    if (noti != null) {
      switch (noti.Action) {
        case 'sync_carpark':
          this.eventBusService.emit(new EmitEvent('refreshMap'));
          break;
        case 'panic_enable':
          this.storage.getProfile().then(profile => {
            let data = JSON.parse(noti.Data);

            profile.panicEnabled = data.enabled;

            if (!profile.panicEnabled) {
              localStorage.removeItem("panicActive");
              localStorage.removeItem('panicSeen');

              if (this.router.url == '/1/sos') {
                this.router.navigate(['/1/home']);
              }
            }

            this.storage.setStorage('profile', profile).then(res => {
              this.eventBusService.emit(new EmitEvent('updateProfile'));
            });
          })

          break;
      }
    }
  }



  /**
   * On destroy
   */
  ngOnDestroy(): void {
    PushNotifications.removeAllListeners();
    // this._unsubscribeAll.next(true);
    // this._unsubscribeAll.complete();
  }
}
