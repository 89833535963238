import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { EmitEvent, EventBusService } from './event-bus.service';
import { MessageService } from './message.service';
import { LocalStorageService } from './local-storage';
import { Router } from '@angular/router';
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from "firebase/messaging";
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';
const app = initializeApp(environment.firebase);

@Injectable({
  providedIn: 'root'
})
export class WebPushService {

  fireMessagingSubscription: any;
  angularFireMessageSubscription: any;
  constructor(private router: Router, private eventBusService: EventBusService, private messageService: MessageService, private storage: LocalStorageService) {
  }

  requestPermission() {
    console.log('Request permission');

    if (!("Notification" in window) || !isSupported()) {
      console.log(
        "Este navegador no es compatible con las notificaciones de escritorio",
      );
    } else {
      Notification.requestPermission().then((permission) => {
        console.log('Notification permission', permission);

        if (permission === 'granted') {
          console.log('Notification permission granted.');
          const messaging = getMessaging();
          getToken(messaging, { vapidKey: environment.firebase_vapidKey }).then(token => {
            console.log('token web', token);
            this.RegisteDevice(token, '');
          })
        }
      })
    }


  }

  receiveMessage() {
    const messaging = getMessaging();
    onMessage(messaging, async (payload) => {
      console.log('Message received. ', payload);
      this.ProcessNotification(payload);
    });


  }

  async ProcessNotification(payload: any) {
    if (payload.data.payload != null) {
      let noti: { Action: string; Data: any } = JSON.parse(
        payload.data.payload
      );
      console.log('notificacion payload', JSON.stringify(noti));
      if (noti != null) {
        switch (noti.Action) {
          case 'sync_carpark':
            this.eventBusService.emit(new EmitEvent('refreshMap'));
            break;
          case 'panic_enable':
            this.storage.getProfile().then(profile => {
              let data = JSON.parse(noti.Data);
              profile.panicEnabled = data.enabled;

              if (!profile.panicEnabled) {
                localStorage.removeItem("panicActive");

                if (this.router.url == '/1/sos') {
                  this.router.navigate(['/1/home']);
                }
              }

              this.storage.setStorage('profile', profile).then(res => {
                this.eventBusService.emit(new EmitEvent('updateProfile'));
              });
            })

            break;
          default:
            if (("Notification" in window) && isSupported()) {
              const notification = new Notification(payload.data['title'], { body: payload.data['message'] });
            }
            break;
        }
      }
    }
  }

  async RegisteDevice(token, deviceArn) {
    console.log(
      'Web register device',
      'token: ' + token + 'deviceArn: ' + deviceArn
    );

    if (token != null && token?.length > 0) {
      let userId = localStorage.getItem('IdUser');
      this.messageService.RegisterDevice(userId, token, Capacitor.getPlatform(), deviceArn).then(
        (res) => {
          console.log('dispositivo registrado', res);
        },
        (err) => {
          console.log('error dispositivo registrado', err);
          console.log('REGISTER_DEVICE', JSON.stringify(err));
        },
      );
    }

  }

  RemoveSubscriptions() {
    console.log('entra a  eliminar subscripciones web');
    if (("Notification" in window) && isSupported()) {
      //this.fireMessagingSubscription?.unsubscribe();
      const messaging = getMessaging();

      getToken(messaging, { vapidKey: environment.firebase_vapidKey }).then(token => {
        console.log('token web', token);
        if (token) {
          deleteToken(messaging)
            .then(() => {
              // La suscripción se ha cancelado con éxito
              console.log('Suscripción cancelada');
            })
            .catch((error) => {
              console.error('Error al cancelar la suscripción:', error);
            });
        }

      })
    }
  }

  // IsSubscribed() {
  //   console.log('angularFireSubscription', this.angularFireSubscription);
  //   console.log(
  //     'angularFireMessageSubscription',
  //     this.angularFireMessageSubscription
  //   );
  //   return (
  //     this.angularFireSubscription != null &&
  //     this.angularFireMessageSubscription != null
  //   );
  // }



}
