import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PanicGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router) { } // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(): Observable<boolean> {
    let panicActive = localStorage.getItem('panicActive');
    console.log('panic active', JSON.stringify(panicActive))
    if (panicActive) {
      this.router.navigate(['/1/sos']);
      return of(false);
    }

    return of(true);
  }
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot,
  // ):
  //   | boolean
  //   | import('@angular/router').UrlTree
  //   | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
  //   | Promise<boolean | import('@angular/router').UrlTree> {
  //   let panicActive = localStorage.getItem('panicActive');
  //   if (panicActive) {
  //     this.router.navigate(['/1/sos']);
  //     return false;
  //   }
  //   return true;
  // }
}
