import * as _ from 'lodash';

export class Notification {
  date: string;
  messages: NotificationDetail[];

  constructor(data?: Partial<Notification>) {
    if (data) {
      this.date = data.date;
      this.messages = _.map(data.messages, (message) => {
        return new NotificationDetail(message);
      });
    }
  }
}

export class NotificationDetail {
  public id: string;
  public title: string;
  public body: string;
  public read: boolean;
  public action: string;
  public icon: string;
  public iconColor: string | null;
  data: string;
  date: string;
  filterBody: string;

  constructor(data?: Partial<NotificationDetail>) {
    if (data) {
      Object.assign(this, data);

      this.icon = data.icon ? data.icon : 'balance';
      this.filterBody = data.body.split('<br>').join(' ');
    }
  }
}
