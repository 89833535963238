import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { DeviceInfo } from '../models/estacionandome.model';

import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) { }

  async getDeviceInfo(): Promise<DeviceInfo> {
    const info = await Device.getInfo();
    const uid = await Device.getId();

    let dInfo = new DeviceInfo();

    dInfo.appVersion = environment.version;
    if (Capacitor.isNativePlatform()) {
      let appInfo = await App.getInfo();
      dInfo.package = appInfo.id;
      dInfo.appVersion = appInfo.version;
    } else {
      dInfo.package = environment.package;
    }

    dInfo.deviceOS = info.osVersion;
    dInfo.deviceId = uid.uuid;
    dInfo.deviceType = info.platform;
    dInfo.device = info.name || '';

    return dInfo;
    // return JSON.stringify(dInfo);
  }

  public async get<T>(endpoint: string, params?: any): Promise<any> {
    let options = { params: this.encodeParams(params) };

    return this.httpClient.get<T>(endpoint, options).toPromise();
  }

  public async post<T>(endpoint: string, params?: any): Promise<any> {
    return this.httpClient.post<T>(endpoint, params).toPromise();
  }

  public async put<T>(endpoint: string, params?: any): Promise<any> {
    return this.httpClient.put<T>(endpoint, params).toPromise();
  }

  public async delete<T>(endpoint: string, params?: any): Promise<any> {
    return this.httpClient.delete<T>(endpoint).toPromise();
  }

  /**
   * Encodes the parameters.
   *
   * @param params The parameters to be encoded
   * @param prefix The parameters to be encoded
   * @return The encoded parameters
   */
  public encodeParams(params: any, prefix?: string) {
    let httpParams: any = new HttpParams();
    let k: string, v: string;
    for (const p in params) {
      if (!params.hasOwnProperty(p)) {
        continue;
      }
      if (p.indexOf('[') >= 0) {
        k = prefix ? prefix + '[' + p.substring(0, p.indexOf('[')) + ']' + p.substring(p.indexOf('[')) : p;
      } else {
        k = prefix ? prefix + '[' + p + ']' : p;
      }
      v = params[p];

      if (typeof v === 'object') {
        _.each(this.encodeParams(v, k).updates, (o) => {
          httpParams = httpParams.set(o.param, encodeURIComponent(o.value));
        });
      } else {
        httpParams = httpParams.set(k, v);
      }
    }
    return httpParams;
  }
}
