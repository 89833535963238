import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard/auth.guard';
import { PanicGuard } from './services/guards/panic-guard';

// import { CommerceAuthGuard } from './services/guards/commerce-info.guard';
// import { AuthGuard } from './services/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs-routing.module').then((m) => m.routes),
    // loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'start',
    loadComponent: () => import('./pages/start/start.page').then((m) => m.StartPage),
    // loadChildren: () => import('./pages/start/start.module').then((m) => m.StartPageModule),
  },
  {
    path: 'style-guide',
    // canActivate: [CommerceAuthGuard],
    loadComponent: () => import('./pages/style-guide/style-guide.page').then((m) => m.StyleGuidePage),
    // loadChildren: () => import('./pages/style-guide/style-guide.module').then((m) => m.StyleGuidePageModule),
  },
  {
    path: 'commerceInfo/:email',
    loadComponent: () => import('./pages/commerce-info/commerce-info.page').then((m) => m.CommerceInfoPage),
    // loadChildren: () => import('./pages/commerce-info/commerce-info.module').then((m) => m.CommerceInfoPageModule),
  },
  {
    path: 'profile',
    canActivate: [PanicGuard],
    // canActivate: [CommerceAuthGuard],
    loadChildren: () => import('./pages/profile/profile-routing.module').then((m) => m.routes),
  },
  {
    path: 'notifications',
    canActivate: [PanicGuard],
    // canActivate: [CommerceAuthGuard],
    loadComponent: () => import('./pages/notifications/notifications.page').then((m) => m.NotificationsPage),
  },
  // {
  //   path: 'support',
  //   // canActivate: [CommerceAuthGuard],
  //   loadChildren: () =>
  //     import('./pages/support/support.module').then((m) => m.SupportPageModule),
  // },
  // {
  //   path: 'monitors',
  //   canActivate: [PanicGuard],
  //   // canActivate: [CommerceAuthGuard],
  //   loadChildren: () => import('./pages/monitors/monitors.module').then((m) => m.MonitorsPageModule),
  // },
  // {
  //   path: 'sos',
  //   // canActivate: [CommerceAuthGuard],
  //   loadChildren: () =>
  //     import('./pages/sos/sos.module').then((m) => m.SosPageModule),
  // },
  {
    path: 'balance',
    canActivate: [PanicGuard],
    // canActivate: [CommerceAuthGuard],
    loadChildren: () => import('./pages/balance/balance.module').then((m) => m.BalancePageModule),
  },
  // {
  //   path: 'transfer',
  //   canActivate: [PanicGuard],
  //   // canActivate: [CommerceAuthGuard],

  //   loadComponent: () => import('./pages/transfer/transfer.page').then((m) => m.TransferPage),
  //   //  loadChildren: () => import('./pages/transfer/transfer.module').then((m) => m.TransferPageModule),
  // },
  {
    path: 'select-amount',
    canActivate: [PanicGuard],
    // canActivate: [CommerceAuthGuard],
    loadChildren: () => import('./pages/select-amount/select-amount.module').then((m) => m.SelectAmountPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordPageModule),
  },
  {
    path: 'pick-address',
    loadComponent: () => import('./pages/pick-address/pick-address.page').then((m) => m.PickAddressPage),
    // loadChildren: () => import('./pages/pick-address/pick-address.module').then((m) => m.PickAddressPageModule),
  },
  {
    path: 'block-commerce/:email',
    loadComponent: () => import('./pages/block-commerce/block-commerce.page').then((m) => m.BlockCommercePage),
    // loadChildren: () => import('./pages/block-commerce/block-commerce.module').then((m) => m.BlockCommercePageModule),
  },
  {
    path: 'code-validation/:email',
    loadChildren: () => import('./pages/code-validation/code-validation.module').then((m) => m.CodeValidationPageModule),
  },
  // { path: '', redirectTo: '1', pathMatch: 'prefix' },
  {
    path: 'confirm-vehicle',
    canActivate: [PanicGuard],
    loadChildren: () => import('./pages/confirm-vehicle/confirm-vehicle.module').then((m) => m.ConfirmVehiclePageModule),
  },
  {
    path: 'add-time',
    canActivate: [PanicGuard],
    // loadChildren: () => import('./pages/add-time/add-time.module').then((m) => m.AddTimePageModule),
    loadChildren: () => import('./pages/add-time/add-time-routing.module').then((m) => m.routes),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      //relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
