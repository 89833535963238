import * as moment from 'moment';
import * as _ from 'lodash';
declare var google;
declare var $;

import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { concat } from 'rxjs';
import { of } from 'rxjs';
import { Subject } from 'rxjs';

export class DeviceInfo {
  public device: string;
  public deviceType: string;
  public deviceId: string;
  public deviceOS: string;
  public appVersion: string;
  public package: string;

  constructor(data?: Partial<DeviceInfo>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export class Datatable<T> {
  public recordsTotal = 0;
  public recordsFiltered = 0;
  public aaData: T[] = [];
  public analytics: any;
}

export class Marker {
  public title: string;
  public Label: string;
  public iconUrl: string;
  public draggable = true;
  public editable = true;
  public visible = true;
  public showInfo = false;
  public Latitude: number;
  public Longitude: number;
  public source: any;
}

export class MapParams {
  public q: string;
  public latCenter: number;
  public lngCenter: number;
  public latNE: number;
  public lngNE: number;
  public distance = 0;

  public getBounds(map) {
    const bounds = map.getBounds();
    const center = bounds.getCenter();
    const ne = bounds.getNorthEast();

    this.latCenter = center.lat();
    this.lngCenter = center.lng();
    this.latNE = ne.lat();
    this.lngNE = ne.lng();

    this.distance = this.getDistance(this.latCenter, this.lngCenter, this.latNE, this.lngNE);
  }

  public getDistance(lat1, lng1, lat2, lng2) {
    const R = 6372.795477598;

    // distancia (A, B) = R * arccos (sen (LATA) * sen (LATB) + cos (lata) * cos (LATB) * cos (LonA-LonB))
    lat1 = (lat1 * Math.PI) / 180.0;
    lng1 = (lng1 * Math.PI) / 180.0;
    lng2 = (lng2 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;
    return R * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng1 - lng2));
  }

  public setItem(key: string, value: any) {
    this[key] = value || '';
    return this;
  }
}

export class Coords {
  lat: number;
  lng: number;
}

export class Polygon {
  public Id: string;
  public Code: string;
  public Name: string;
  public Path: string | any = '[]';
  public FillColor: string;
  public Latitude: number;
  public Longitude: number;
  public CreationDate: string = moment().format('YYYY/MM/DD HH:mm:ss');
  public DateOfLastChange: string = moment().format('YYYY/MM/DD HH:mm:ss');
  public Deleted = false;
  public ParkingMeter = 0;
  public CarPark = 0;
  public MinutesForForcedRotation = 480;
  public MaxMinutesByTicket = 360;
  public MinMinutesByTicket = 5;
  public MinutesResolution = 5;
  public Country: string;
  public City: string;
  public Colony: string;
  public Zone: string;
  public isNew = false;
  public source: any;
  public IdPolygonTimeShare: number;
  public IdPolygonShare: string[] = [];

  public center() {
    const bounds = new google.maps.LatLngBounds();
    _.each(this.Path, (_p) => {
      bounds.extend(_p);
    });

    const center = bounds.getCenter();
    this.Latitude = center.lat();
    this.Longitude = center.lng();

    return center;
  }

  public getDistance(lat1, lng1, lat2 = this.Latitude, lng2 = this.Longitude) {
    const R = 6372.795477598;

    // distancia (A, B) = R * arccos (sen (LATA) * sen (LATB) + cos (lata) * cos (LATB) * cos (LonA-LonB))
    lat1 = (lat1 * Math.PI) / 180.0;
    lng1 = (lng1 * Math.PI) / 180.0;
    lng2 = (lng2 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;
    return R * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng1 - lng2));
  }
}

// export class Select2<T> {
//     public data = new BehaviorSubject<T[]>([]);
//     public dataBuffer = new BehaviorSubject<T[]>([]);
//     public list: Observable<T[]>;
//     public loading = false;
//     public input: BehaviorSubject<string>;
//     public vs: Subject<string> = new Subject();
//     public buffer: any[] = [];
//     public bufferSize = 50;
//     public fetchingMore = 10;

//     constructor(callback: any, query: string = '', key = '', min = 0) {
//         this.input = new BehaviorSubject<string>(query);
//         this.list = concat<T[]>(
//             of([]), // default items
//             this.input
//                 .debounceTime(200)
//                 .switchMap((term) => {
//                     if ((term || '').length < min) { return of([]); }
//                     this.loading = true;
//                     return callback(term || query)
//                         .distinctUntilChanged()
//                         .catch(() => of([])) // empty list on error
//                         .map((data: any[]) => {
//                             this.loading = false;
//                             this.data.next(data);
//                             this.dataBuffer.next(data);
//                             if (!key) {
//                                 return data;
//                             } else {
//                                 this.buffer = data.slice(0, this.bufferSize);
//                                 return this.buffer;
//                             }
//                         })
//                 })
//         );

//         this.vs
//             .debounceTime(200)
//             .subscribe((term) => {
//                 this.loading = true;
//                 this.data
//                     .debounceTime(200)
//                     .subscribe((data) => {
//                         this.loading = false;
//                         this.dataBuffer.next(data.filter(d => d[key].toLowerCase().includes((term || '').toLowerCase())));
//                         this.buffer = this.dataBuffer.getValue().slice(0, this.bufferSize);
//                     });
//             });

//         if (key) { this.list.subscribe(); }
//     }

//     onScrollToEnd() {
//         this.fetchMore();
//     }

//     onScroll({ end }) {
//         if (this.loading || this.dataBuffer.getValue().length === this.buffer.length) {
//             return;
//         }

//         if (end + this.fetchingMore >= this.buffer.length) {
//             this.fetchMore();
//         }
//     }

//     private fetchMore() {
//         const len = this.buffer.length;
//         const more = this.dataBuffer.getValue().slice(len, this.bufferSize + len);
//         this.loading = true;
//         // using timeout here to simulate backend API delay
//         setTimeout(() => {
//             this.loading = false;
//             this.buffer = this.buffer.concat(more);
//         }, 200)
//     }
// }

export class CarParkValidation {
  public IdCarPark: string;
  public CarParkCode: string;
  public Latitude: number;
  public Longitude: number;

  public IdVehicle: string;
  public Plate: string;
  public Brand: string;
  public Model: string;
  public Color: string;
  public Anio: number;
  public MessageWhenParking: string;

  public isNeightbour: number;

  public outDate: string = moment().format('YYYY/MM/DD HH:mm:ss');
  public maxEndDate: string;
  public isParked: boolean;

  // public List<PolygonTimeInformation> PolygonTimeInf;

  public InfringementCount: number;
  public InfrigementCode: string;
  public InfrigementNote: string;
  public InfrigementTime: string;
  public InfrigementFUT: string;

  public hassToPayDamagedClamp: boolean;

  public InfigementClamp01: string;
  public InfigementClamp02: string;
  public InfigementClamp03: string;
  public InfigementClamp04: string;

  public InfigementClamp01IsDamaged: string;
  public InfigementClamp02IsDamaged: string;
  public InfigementClamp03IsDamaged: string;
  public InfigementClamp04IsDamaged: string;

  public ImmediateCrane: boolean;
  public InfrigementPayDate: string;
  public InfrigementPayClampDate: string;

  public InfrigementCarParkCode: string;

  public CraneDate: string;
}

export class Notifications {
  public Id: string;
  public To: string;
  public From: string;
  public Title: string;
  public BodyMessage: string;
  public BodyEmail: string;
  public CreatedAt: Date;
  public IsMessage: boolean;
  public IsEmail: boolean;
  public IsHtmlEmail: boolean;
  public Channel: number;
  public IsOnlineBroadcast: boolean;
  public Deleted: boolean;
  public DeliveredAt: Date;
  public ReadAt: Date;
}

export class Panic {
  public Id: string;
  public UserId: string;
  public Latitude: number;
  public Longitude: number;
  public IsPEC: boolean;
  public PanicDate: string;
  public CancelDate: string;
  public IdUserCanceled: string;
  public NameUserCanceled: string;
  public Range: number;
  public PolygonId: string;
  public Contact: string;
  public Email: string;
  public Name: string;
  public PanicLevel: number;
  public PanicType: string;
  public IdPanicType: number;
  public Channel: number;
  public FileName: string;
}

export class CarParkState {
  CarParkCode: string;
  Code: string;

  PolygonCode: string;

  CarParkId: string;

  CarParkCapability: number;

  CarParkUse: number;

  Latitude: number;

  Longitude: number;

  lastCheck: Date;

  Deleted: boolean;

  Disabled: boolean;

  PoligonId: string;

  Address: string;

  ForcedStatus: number;

  Icon: string;

  Marker: any;

  visible: boolean;
}

export interface Carpark {
  Id: string;
  Code: string;
  IdPolygon: string;
  PolygonName: string;
  Status: string;
  Latitude: number;
  Longitude: number;
  CreationDate: string;
  Deleted: boolean;
  Disabled: boolean;
  Type: string;
  Address: string;
  Label: string;
  capability: number;
  Icon: null;
}

export class VehicleInfo {
  id: string;
  plate: string;
  name: string;
  email: string;
  year: number;
  model: string;
  brand: string;
  color: string;
  isParked: boolean;
  outDate: Date;
  clamp1: string;
  clamp2: string;
  clamp3: string;
  clamp4: string;
  isNeightbour: number;
}

export class UserVehicle extends DeviceInfo {
  public Id: string;
  public Alias: string;
  public Plate: string;
  public Color: string;
  public Brand: string;
  public Model: string;
  public Year: number;
  public ParkLotOcupation: number;
  public IdVehicleCatalog: number;

  public IsAlreadyParked: boolean;
  public IsInfracted: boolean;
  public IsImmobilized: boolean;

  public ParkCarParkId: string;
  public ParkEndDateUTC: Date;

  public Latitude: number;
  public Longitude: number;

  public CarparkType: string;
  public CarparkAddress: string;
  public CarparkCode: string;
  public CarparkPolygonCode: string;

  public IsNeightbour: boolean;
  public NeightbourState: string;
  public NeightbourValidTo: Date;
  public NeightbourPolygons: string[];

  public HasTime: boolean;
  public OwnersCount: number;

  get countDown(): string {
    // return moment(this.ParkEndDateUTC).diff(moment(), 'seconds');

    var date1 = moment(this.ParkEndDateUTC);
    var date2 = moment();

    var ms = date1.diff(date2);
    var duration = moment.duration(ms);
    var h = duration.hours();
    var m = duration.minutes();
    var s = duration.seconds();

    if (ms < 0) {
      return `0h 0m 0s`;
    }

    return `${h}h ${m}m ${s}s`;
  }
}

export enum PanicChannel {
  CND = 1,
  COM = 2,
  PEC = 3,
}

export interface Zone {
  Id: string;
  Name: string;
  IdPolygon: string;
  PolygonCode: string;
  Path: string;
  FillColor: string;
  Latitude: number;
  Longitude: number;
  FillColor_CND: string;
  CarParkLabel: string;
  Path_CND: string;
  Latitude_CND: number;
  Longitude_CND: number;
  ForcedText: string;
  VisibleToCND: boolean;
  MinAvailability: number;
  MaxAvailability: number;
  ForcedStatus: number;
  Capability: number;
  Use: number;
  Availability: number;
  AvailabilityPercent: number;
  Delta: number;
  OverUse: number;
  AvailabilityToShow: number;
  lastCheck: string;
  Deleted: boolean;
  lastChange: string;
  creationDate: string;
  PolygonName: string;
  PolygonCountry: string;
  PolygonCity: string;
  PolygonColony: string;
  PolygonZone: string;
}

export class PrintParameter {
  key: string;
  value: string;
  name: string;

  constructor(key: string, value: string, name: string) {
    this.key = key;
    this.value = value;
    this.name = name;
  }
}

export class PrintModel {
  // IdCreator: string;
  FormName: string;
  OperatorName: string;
  UserLocalNow: Date;
  Number: string;

  MotiveCode: string;
  MotiveNote: string;
  MotiveFee: string;

  Parameters: PrintParameter[];
}

export interface ParkLimit {
  minMinutes: number;
  maxMinutes: number;
  minutesResolution: number;
  isAlreadyParked: boolean;
  avaliableMinutes: number;
  avaliableAmmount: number;
  minutesParkAmmount: number;
  timeToDepark: string;
  message: ParkLimitMessage;
  polygonTimeInformation: PolygonTimeInformation[];
}

export interface ParkLimitMessage {
  Detail: string;
  Type: string;
  isError: boolean;
  show: boolean;
  close_session: boolean;
}

export interface PolygonTimeInformation {
  PolygonId: string;
  PolygonName: string;
  IsParked: boolean;
  ParkedDate: string;
  DeparkedDate: string;
  IsCurrentWorkTime: boolean;
  AvailableMinutes: number;
  DeparkTime: string;
  NextBlockStartDate: string;
}

export interface QuickOption {
  amount: number;
  minutes: number;
  outDate: string;
}
