import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, from, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth-service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage';
import * as _ from 'lodash';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  logTag = 'INTERCEPTOR: '
  constructor(private _authService: AuthService, private _localStorage: LocalStorageService, private api: ApiService) { }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    // let newReq = req.clone();

    // if (req.url.startsWith(environment.serverUrl)) {
    //   newReq = this.addAuthenticationToken(newReq);
    // }

    // // Response
    // return next.handle(newReq).pipe(
    //   catchError((error) => {
    //     console.log('🚀 ~ file: auth.interceptor.ts:72 ~ AuthInterceptor ~ catchError ~ error:', JSON.stringify(error));
    //     // Catch "401 Unauthorized" responses
    //     if (error instanceof HttpErrorResponse && error.status === 401) {
    //       // Sign out
    //       this._authService.removeUserData().then(() => {
    //         // Reload the app
    //         location.reload();
    //       });
    //     }

    //     return throwError(error);
    //   }),
    // );

    return from(this.handleRequest(req, next));
  }

  private addAuthenticationToken(req) {
    // Get access token from Local Storage
    const accessToken = this._authService.accessToken;

    let hasToken = !(_.isEmpty(accessToken) || _.isUndefined(accessToken) || _.isNull(accessToken));

    // We clone the request, because the original request is immutable

    if (hasToken) {
      return req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + accessToken),
      });
    }
    // If access token is null this means that user is not logged in
    // And we return the original request
    else return req;
  }

  async handleRequest(req: HttpRequest<any>, next: HttpHandler) {
    let newReq = req.clone();
    let deviceInfo = await this.api.getDeviceInfo();

    if (req.url.startsWith(environment.serverUrl)) {
      newReq = this.addAuthenticationToken(newReq);

      let serializedDeviceInfo = JSON.stringify(deviceInfo);
      newReq = newReq.clone({ setHeaders: { 'deviceInfo': serializedDeviceInfo } })

      // console.log('currentRequest', newReq);

      if (newReq.body?.deviceInfo == undefined) {

        if (newReq.method == 'POST' || newReq.method == 'PUT') {
          let currentBody = newReq.body;

          _.set(currentBody, 'deviceInfo', deviceInfo)
          //let newBody = newReq.clone({ body: currentBody })

          newReq = newReq.clone({ body: currentBody });

          console.log(this.logTag + newReq.method + ' url: ' + newReq.url + ' no tiene deviceInfo se agregan nuevos parametros', newReq)

          // console.log('currentRequest se agrega deviceInfo', newReq);
        } else {

          if (!newReq.urlWithParams.includes('deviceInfo')) {
            let currentParams = newReq.params;
            // console.log('currentParams', currentParams);

            let deviceInfoParams: HttpParams = this.api.encodeParams({ deviceInfo });
            // console.log('deviceinfoParams', deviceInfoParams.keys())

            let appVersion = deviceInfoParams.get("deviceInfo[appVersion]")
            let package1 = deviceInfoParams.get("deviceInfo[package]")
            let deviceOS = deviceInfoParams.get("deviceInfo[deviceOS]")
            let deviceId = deviceInfoParams.get("deviceInfo[deviceId]")
            let deviceType = deviceInfoParams.get("deviceInfo[deviceType]")
            let device = deviceInfoParams.get("deviceInfo[device]")

            let newParams = new HttpParams({ fromString: currentParams.toString() });
            newParams = newParams.set("deviceInfo[appVersion]", appVersion);
            newParams = newParams.set("deviceInfo[package1]", package1);
            newParams = newParams.set("deviceInfo[deviceOS]", deviceOS);
            newParams = newParams.set("deviceInfo[deviceId]", deviceId);
            newParams = newParams.set("deviceInfo[deviceType]", deviceType);
            newParams = newParams.set("deviceInfo[device]", device);

            newReq = newReq.clone({ params: newParams });
            console.log(this.logTag + newReq.method + ' url: ' + newReq.url + ' no tiene deviceInfo se agregan nuevos parametros', newReq)
          }
        }
      }

    }

    return await lastValueFrom(next.handle(newReq).pipe(
      catchError((error) => {
        console.log('🚀 ~ file: auth.interceptor.ts:72 ~ AuthInterceptor ~ catchError ~ error:', JSON.stringify(error));
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          this._authService.removeUserData().then(() => {
            // Reload the app
            location.reload();
          });
        }

        return throwError(error);
      }),
    ));
  }


  // async handleRequestWithHeader(req: HttpRequest<any>, next: HttpHandler) {
  //   let newReq = req.clone();
  //   let deviceInfo = await this.api.getDeviceInfo();

  //   if (req.url.startsWith(environment.serverUrl)) {
  //     newReq = this.addAuthenticationToken(newReq);
  //     let serializedDeviceInfo = JSON.stringify(deviceInfo);
  //     newReq = newReq.clone({ setHeaders: { 'deviceInfo': serializedDeviceInfo } })
  //     console.log(this.logTag + 'request con device info en header', newReq);
  //   }

  //   return await lastValueFrom(next.handle(newReq).pipe(
  //     catchError((error) => {
  //       console.log('🚀 ~ file: auth.interceptor.ts:72 ~ AuthInterceptor ~ catchError ~ error:', JSON.stringify(error));
  //       // Catch "401 Unauthorized" responses
  //       if (error instanceof HttpErrorResponse && error.status === 401) {
  //         // Sign out
  //         this._authService.removeUserData().then(() => {
  //           // Reload the app
  //           location.reload();
  //         });
  //       }

  //       return throwError(error);
  //     }),
  //   ));
  // }
}
