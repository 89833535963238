import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { GoogleLoginProvider, FacebookLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
// import { environment } from 'src/environments/environment.prod';
// import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
// import { Camera } from '@ionic-native/camera/ngx';
// import { Facebook } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
// import { IonicGestureConfig } from './utils/IonicGestureConfig';
// import { MediaCapture } from '@ionic-native/media-capture/ngx';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { WebPushingService } from './services/webpushing.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PanicGuard } from './services/guards/panic-guard';
import { AuthInterceptor } from './services/interceptor/error.interceptor';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



