import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UploadImage } from '../models/profile';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage';
import { CurrentUser } from '../models/current-user';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authenticated: boolean = false;

  constructor(private _httpClient: HttpClient, private _localStorage: LocalStorageService, private apiService: ApiService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    // this._localStorage.setStorage('access_token', token);
    localStorage.setItem('access_token', token);
  }

  get accessToken(): string {
    return localStorage.getItem('access_token') ?? '';
  }

  public encodeParams(params: any): string {
    let body = '';
    for (const key in params) {
      if (body.length) {
        body += '&';
      }
      body += key + '=';
      body += encodeURIComponent(params[key]);
    }

    return body;
  }

  async signUp(data: any = {}) {
    // const user = new User(this.http);
    const tokenEndpoint: string = environment.api('api/Account/register');
    let deviceInfo = await this.apiService.getDeviceInfo();

    const params: any = {
      Email: data.email,
      Name: data.fullName,
      Password: data.password,
      ConfirmPassword: data.password,
      deviceInfo,
    };

    return this.apiService.post(tokenEndpoint, params);
  }

  signIn(data: any = {}): Observable<any> {
    // Throw error, if the user is already logged in
    // if (this._authenticated) {
    //   return throwError(() => 'User is already logged in.');
    // }
    // Llamamos al api.net
    const tokenEndpoint: string = environment.api('Token');

    const params: any = {
      grant_type: 'password',
      username: data.username,
      password: data.password,
    };

    // Encodes the parameters.
    const body: string = this.encodeParams(params);

    return this._httpClient.post(tokenEndpoint, body).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.access_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        return of(response);
      }),
    );
  }

  /**
   * Sign In OAuth
   *
   * @param token
   */

  singInOAuht(
    user: { email: string; first_name: string; last_name: string; picture: string },
    access_token: string,
    provider: string,
  ): Observable<any> {
    let tokenEndpoint: string;

    // let deviceInfo = await this.apiService.getDeviceInfo();
    tokenEndpoint = environment.api('api/account/RegisterExternalToken');

    let params = {
      Email: user.email,
      Token: access_token,
      Provider: provider,
      Name: user.first_name + ' ' + user.last_name,
      Avatar: user.picture,
      // deviceInfo,
    };

    return this._httpClient.post(tokenEndpoint, params).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.access_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        return of(response);
      }),
    );
  }

  public changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
    const datos = {
      OldPassword: oldPassword,
      NewPassword: newPassword,
      ConfirmPassword: confirmPassword,
    };
    const tokenEndpoint: string = environment.serverUrl + 'api/Account/ChangePassword';

    //return this.http.post(tokenEndpoint, datos, this.setAuthorization());
    return this.apiService.post(tokenEndpoint, datos);
  }

  async signOut() {
    let deviceInfo = await this.apiService.getDeviceInfo();

    const tokenEndpoint: string = environment.api('api/Account/Logout');

    const params: any = {
      deviceInfo,
    };

    this.apiService.post(tokenEndpoint, params).then((response) => {
      // Set the authenticated flag to false
      this._authenticated = false;
      this.removeUserData();
    });
  }

  public async removeUserData(): Promise<void> {
    // Set the authenticated flag to false
    this._authenticated = false;

    await this._localStorage.clear();
    localStorage.removeItem('access_token');
  }

  async forgotPassword(email: string) {
    const tokenEndpoint: string = environment.serverUrl + 'api/Account/RecoverPassword';
    let deviceInfo = await this.apiService.getDeviceInfo();
    let params = {
      email,
      deviceInfo
    }

    return this.apiService.post(tokenEndpoint, params);
  }

  getCurrent(): any {
    if (localStorage.getItem('email') && localStorage.getItem('access_token') && localStorage.getItem('expires')) {
      const ValidoToken = this.tokenNotExpired();
      return {
        userName: localStorage.getItem('userName'),
        tokenValidado: ValidoToken,
        authData: {
          facebook: localStorage.getItem('facebook') && localStorage.getItem('facebook').length > 0,
          google: localStorage.getItem('google') && localStorage.getItem('google').length > 0,
          token: localStorage.getItem('access_token'),
          tokenExpiration: localStorage.getItem('expires'),
        },
      } as CurrentUser;
    } else {
      return null;
    }
  }

  async validateCode(code: any = {}): Promise<any> {
    let deviceInfo = await this.apiService.getDeviceInfo();

    const tokenEndpoint: string = environment.api('api/Account/EmailConfirmation');

    const params: any = {
      Token: code,
      deviceInfo,
    };

    return this.apiService.post(tokenEndpoint, params);
  }

  async resendEmailConfirmation(email: string) {
    let deviceInfo = await this.apiService.getDeviceInfo();

    const tokenEndpoint: string = environment.api('api/Account/ResendAccountConfirmationEmail');

    const params: any = {
      Email: email,
      deviceInfo,
    };

    return this.apiService.post(tokenEndpoint, params);
  }

  tokenNotExpired(): boolean {
    if (localStorage.getItem('expires')) {
      const FechaExpiracion = new Date(localStorage.getItem('expires'));

      return FechaExpiracion >= new Date();
    } else {
      return false;
    }
  }

  async getLinkedAccounts(IdUser: string) {
    let deviceInfo = await this.apiService.getDeviceInfo();

    let params = {
      deviceInfo,
    };

    const url = environment.serverUrl + 'api/account/getLinkedAccounts?userId=' + IdUser;

    return this.apiService.get(url, params);
  }

  async unlinkAccount(data: any) {
    let deviceInfo = await this.apiService.getDeviceInfo();

    let params = {
      IdUser: data.IdUser,
      rrss: data.rrss,
      deviceInfo,
    };

    const url = environment.serverUrl + 'api/account/unlinkAccounts';

    return this.apiService.post(url, params);
  }
}
