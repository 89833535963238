import { Injectable } from '@angular/core';
import { UserProfile } from '../models/profile';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() { }

  async removeItem(key: string) {
    await Preferences.remove({ key: key });
  }

  async clear() {
    await Preferences.clear();
  }

  async setStorage(key: string, object: any) {
    await Preferences.set({
      key: key,
      value: JSON.stringify(object),
    });
  }

  async getStorage(key: string) {
    const { value } = await Preferences.get({ key: key });

    if (value !== 'undefined') return JSON.parse(value || '{}');
    else return {};
  }



  // setSkipIntroPage(val: boolean): Promise<any> {
  //   // return this.storage.set('skipIntroPage', val);
  //   return this.setStorage('skipIntroPage', val);
  // }

  // getSkipIntroPage(): Promise<any> {
  //   // return this.storage.get('skipIntroPage');
  //   return this.getStorage('skipIntroPage');
  // }

  // setUnit(val: string): Promise<any> {
  //   // return this.storage.set('unit', val);
  //   return this.setStorage('unit', val);
  // }

  // getUnit(): Promise<any> {
  //   return this.getStorage('unit');
  //   // return this.storage.get('unit');
  // }

  // setLang(val: string): Promise<any> {
  //   // return this.storage.set('lang', val);
  //   return this.setStorage('lang', val);
  // }

  // async getLang(): Promise<any> {
  //   const { value } = await Preferences.get({ key: 'lang' });
  //   return value;
  // }

  // getLastPosition(): Promise<LocationAddress> {
  //   return this.getStorage('lastPosition');
  //   // return this.storage.get('lastPosition');
  // }

  // setLastPosition(val: LocationAddress) {
  //   // return this.storage.set('lastPosition', val);
  //   return this.setStorage('lastPosition', val);
  // }

  // getDefaultCard(): Promise<string> {
  //   return this.getStorage('cardid');
  //   // return this.storage.get('cardid');
  // }

  // setDefaultCard(val: string) {
  //   // return this.storage.set('cardid', val);
  //   return this.setStorage('cardid', val);
  // }

  // deleteDefaultCard() {
  //   return this.removeItem('cardid');
  //   // return this.storage.remove('cardid');
  // }

  getProfile(): Promise<UserProfile> {
    return this.getStorage('profile');
    // return this.storage.get('profile');
  }

  // setProfile(val: UserProfile) {
  //   // return this.storage.set('profile', val);
  //   return this.setStorage('profile', val);
  // }

  // getCarparks(): Promise<CarParkState[]> {
  //   return this.getStorage('carparks');
  //   // return this.storage.get('carparks');
  // }

  // setCarparks(val: CarParkState[]) {
  //   // return this.storage.set('carparks', val);
  //   return this.setStorage('carparks', val);
  // }

  // getLastCarParkCheck(): Promise<string> {
  //   return this.getStorage('lastCarparkCheck');
  //   // return this.storage.get('lastCarparkCheck');
  // }

  // setLastCarParkCheck(val: string) {
  //   // return this.storage.set('lastCarparkCheck', val);
  //   return this.setStorage('lastCarparkCheck', val);
  // }

  // clearUserStorage() {
  //   return Promise.all([
  //     this.removeItem('cardid'),
  //     this.removeItem('profile'),
  //     this.removeItem('snsToken'),
  //     this.removeItem('deviceArn'),
  //   ]);
  // }

  // getSNSToken(): Promise<string> {
  //   return this.getStorage('snsToken');
  //   // return this.storage.get('snsToken');
  // }

  // setSnsToken(val: string) {
  //   return this.setStorage('snsToken', val);
  //   // return this.storage.set('snsToken', val);
  // }

  // getDeviceArn(): Promise<string> {
  //   return this.getStorage('deviceArn');
  //   // return this.storage.get('deviceArn');
  // }

  // setDeviceArn(val: string) {
  //   return this.setStorage('deviceArn', val);
  //   // return this.storage.set('deviceArn', val);
  // }

  // //Rappi data

  // getCookie(): Promise<string> {
  //   return this.getStorage('rappiCookie');
  //   // return this.storage.get('rappiCookie');
  // }

  // setCookie(val: string) {
  //   return this.setStorage('rappiCookie', val);
  //   // return this.storage.set('rappiCookie', val);
  // }

  // getRappiToken(): Promise<string> {
  //   return this.getStorage('rappiToken');
  //   // return this.storage.get('rappiToken');
  // }

  // setRappiToken(val: string) {
  //   return this.setStorage('rappiToken', val);
  //   // return this.storage.set('rappiToken', val);
  // }

  // getRappiTokenExpiration(): Promise<string> {
  //   return this.getStorage('rappiTokenExpiration');
  //   // return this.storage.get('rappiTokenExpiration');
  // }

  // setRappiTokenExpiration(val: string) {
  //   return this.setStorage('rappiTokenExpiration', val);
  //   // return this.storage.set('rappiTokenExpiration', val);
  // }
}
