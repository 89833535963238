import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Notification } from '../models/notification';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private _notifications = new BehaviorSubject<Notification[]>([]);

  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }
  set notifications(value: Notification[]) {
    this._notifications.next(value);
  }

  constructor(private apiService: ApiService) { }

  async getMessages() {
    let deviceInfo = await this.apiService.getDeviceInfo();

    let params = {
      deviceInfo,
    };

    return this.apiService.get(environment.api('api/notification/user'), params).then((response: any) => {
      let messages: Notification[] = _.map(response.Data, (vehicle) => {
        return new Notification(vehicle);
      });

      this._notifications.next(messages);

      return response;
    });
  }

  getUnreadMessages(userId: string) {
    return this.apiService.get(environment.api('api/notification/unreadMessages'), {
      userId,
    });
  }

  async getMessageDetail(messageId: string) {
    let deviceInfo = await this.apiService.getDeviceInfo();

    let params = {
      messageId: messageId,
      deviceInfo: deviceInfo,
    };

    return this.apiService.get(environment.api('api/notification/detail'), params);
  }

  async updateMessage(notificationId: string, userId: string, deleted: boolean) {
    let deviceInfo = await this.apiService.getDeviceInfo();

    let params = {
      // notification: {
      notificationId,
      userId,
      deleted,
      // },
      deviceInfo,
    };

    return this.apiService.post(environment.api('api/notification/update'), params);
  }

  async markAllMessagesAsRead() {
    let deviceInfo = await this.apiService.getDeviceInfo();

    let params = {
      deviceInfo,
    };

    return this.apiService.get(environment.api('api/notification/read_all'), params);
  }

  async showBadge() {
    let deviceInfo = await this.apiService.getDeviceInfo();

    let params = {
      deviceInfo: deviceInfo,
    };

    return this.apiService.get(environment.api('api/notification/show_badge'), params);
  }

  async RegisterDevice(userId: string, token: string, platform: string, deviceEndpointArn: string) {
    let deviceInfo = await this.apiService.getDeviceInfo();
    let params = {
      userId,
      token,
      platform,
      deviceEndpointArn,
      deviceInfo,
    };

    console.log('register device data', JSON.stringify(params))
    return this.apiService.post(environment.api('api/notification/register_device'), params);
  }

  // getUnreadMessages(): number {
  //   let unreadMessages = 0;
  //   if (this._notifications.getValue().length > 0) {
  //     this._notifications.getValue().forEach((g, i) => {
  //       const arr = g.messages as Array<any>;

  //       arr.forEach((item, index) => {
  //         if (!item.read) unreadMessages++;
  //       });
  //     });

  //     return unreadMessages;
  //   }

  //   return unreadMessages;
  // }
}
