// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: 'https://negocio-qas.yoplataforma.com/',
  //serverUrl: 'http://localhost:53879/',

  s3Path: 'https://opevsa-files.s3.amazonaws.com/',

  appUrl: 'https://trynearme.app',
  appImageUrl: 'https://trynearme.app/assets/img/nearme.png',
  googleMapsApiKey: 'AIzaSyCcYRvrJojfLQicNFqUeSAM8x2mPCL8nX0',
  googleTranslateApiKey: 'AIzaSyBCzyvmd1BYpaMJ2H3AZR-0wSXZ7wwiawU',
  openALPR_SK: 'sk_e8f7d9045ef8c6fb1bf60e8a',
  androidHeaderColor: '#d82c6b',
  defaultUnit: 'mi',
  defaultLang: 'es',
  radiusSearch: 0,
  version: '2.0.0.1',
  default_coord: {
    latitude: 19.43313,
    longitude: -99.154818,
  },
  api: function (endpoint: string) {
    return this.serverUrl + endpoint;
  },
  COGNITO_IDENTITY: {
    IDENTITY_POOL_ID: 'us-east-1:df263afb-d531-4fda-a112-68ab648954df',
    REGION: 'us-east-1',
  },
  S3: {
    BUCKET_NAME: 'opevsa-files',
    AWS_ACCESS_KEY_ID: 'AKIAIIJMXW4SMZ7HIUJQ',
    AWS_SECRET_ACCESS_KEY: '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',
    AWS_REGION: '',
  },
  firebase: {
    apiKey: "AIzaSyAET07sQY5Ib1s6bLQz1iWIAAz-9rbWIvA",
    authDomain: "estacionamiento-digital.firebaseapp.com",
    projectId: "estacionamiento-digital",
    storageBucket: "estacionamiento-digital.appspot.com",
    messagingSenderId: "323375357304",
    appId: "1:323375357304:web:5999765148e81e296a7c8d",
    measurementId: "G-MZMJEDRDTK"
  },
  firebase_vapidKey: 'BK9XNy19febYy5lEcoXe0JINnfxj44M8oU7PNTMazK1BMK1uV6TevD-59Q_U7dz-a9y7-go6CFk7C1pN6Dn0L5U',
  social_logins: {
    facebook: { app_id: '1220822608583282' },
    google: { client_id: '323375357304-lmtvd01i34nl9a7rsqp4mtvi92k9qe12.apps.googleusercontent.com' },
    apple: { client_id: '', redirect_uri: '' },
  },
  package: 'com.yo.plataforma.cdmx.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
